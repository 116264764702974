export const isRequired = ({ value = '' }) => !value.toString().trim().length;
export const maxLength = ({ value = '', max }) => value.trim().length > max;
export const maxSizeFile = ({ value, max }) => value?.size > max;

export const isRelevanceEmail = ({ value = '' }) =>
  !String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
