import { useState, useEffect } from 'react';

const useForm = (onSubmit = () => {}, initialForm = [], validateOnChange) => {
  const [data, setData] = useState({});
  const [validated, setValidated] = useState(false);
  const [errors, setFormErrors] = useState({});

  useEffect(() => {
    if (validateOnChange && validated) {
      setFormErrors(validate(data));
    }
  }, [validateOnChange, data]);

  const validate = () => {
    let errors = {};
    for (const field of initialForm) {
      if (field?.validators && Array.isArray(field.validators)) {
        for (const validator of field.validators) {
          if (
            validator.func({
              value: data[field.name],
              ...(validator.extraParams && validator.extraParams),
            })
          ) {
            errors = Object.assign(errors, { [field.name]: validator.message });
          }
        }
      }
    }
    return errors;
  };

  const isValid = (field) => validated && !errors[field];

  const isInvalid = (field) => validated && errors[field];

  const inputsTypeMap = {
    FILE: 'file',
  };

  const onChange = ({
    target: { type = 'text', name, value = '', files = [] },
  }) => {
    if (value === '') {
      const _data = { ...data };
      delete _data[name];
      setData(_data);
      return;
    }

    if (type === inputsTypeMap.FILE) {
      setData({
        ...data,
        [name]: files[0],
      });
      return;
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const resetFormState = () => {
    setData({});
    setFormErrors({});
    setValidated(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(data);

    if (Object.keys(errors).length === 0) {
      onSubmit(data);
      resetFormState();
    } else {
      setValidated(true);
    }
    setFormErrors(errors);
  };

  return {
    data,
    errors,
    onChange,
    isValid,
    isInvalid,
    handleSubmit,
    resetFormState,
  };
};

export default useForm;
