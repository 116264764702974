import React from 'react';
import { useTranslation } from 'react-i18next';

const Thanks = ({hideThanksModal}) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="thanks-title">{t('thanksModal.title')}</p>
      <div className="thanks-text">{t('thanksModal.text')}</div>
      <div className="form-group-btn small-btn">
        <button className={'btn submit'} onClick={hideThanksModal}>
          {t('thanksModal.btn')}
        </button>
      </div>
    </>
  );
};

export default Thanks;
