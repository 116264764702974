import Aos from 'aos';
import React, { useEffect } from 'react';

const ModalForm = ({
  show = false,
  onHide = () => {},
  title,
  children = <></>,
}) => {
  const classForModalWrapper = 'modal-wrapper';

  const closeHandler = () => {
    onHide(false);
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
    Aos.refresh();
  }, []);

  const onWrapperHandler = ({ target: { className } }) => {
    className === classForModalWrapper && closeHandler();
  };

  return (
    show && (
      <div className={classForModalWrapper} onClick={onWrapperHandler}>
        <div className="modalBox" data-aos="fade-down">
          <div className="modalBox-header">
            <div className="modalBox-header_controlle">
              <button onClick={closeHandler}>&times;</button>
            </div>
            {title && <h3 className="modalBox-header_title">{title}</h3>}
          </div>
          <div className="modalBox-body">{children}</div>
        </div>
      </div>
    )
  );
};

export default ModalForm;
